/*@import '@fullcalendar/core/main.css';*/
/*@import '@fullcalendar/daygrid/main.css';*/
/*@import '@fullcalendar/interaction/main.css';*/

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/Rubik-Regular.woff') format('woff');
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

* {
  box-sizing: border-box;
}

/* Apply global font family */
@layer base {
  body {
    @apply dark:bg-dark-layout-background bg-light-layout-background font-rubik;
  }

  h1 {
    @apply text-h1 font-rubik;
  }

  h2 {
    @apply text-h2 font-rubik;
  }

  h3 {
    @apply text-h3 font-rubik;
  }

  h4 {
    @apply text-h4 font-rubik;
  }

  h5 {
    @apply text-h5 font-rubik;
  }

  h6 {
    @apply text-h6 font-rubik;
  }

  p {
    @apply text-body-lg font-rubik;
  }

  div {
    @apply font-rubik;
  }

  span {
    @apply font-rubik;
  }
}

@layer components {
  [data-slot='helper-wrapper']:empty {
    display: none !important;
  }
}

/* Hide spinner controls in Chrome, Safari, and Edge */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* Remove the default appearance */
  margin: 0;
  /* Remove margin */
}

/* Hide spinner controls in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  /* Remove the default appearance */
}

.custom-scrollbar {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #f1f1f1;
  /* Thumb and track colors for Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Light gray background */
  border-radius: 5px;
  /* Optional: round corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar */
  border-radius: 5px;
  /* Optional: round corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color on hover */
}

.custom-opacity {
  opacity: 1 !important;
}

/* Hide the default close button */
.gm-ui-hover-effect {
  display: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  /*border-top: 2px solid #EDEFF1; !* Top border *!*/
  /*border-left: 2px solid #EDEFF1; !* Left border *!*/
  /*border-right: 2px solid #EDEFF1; !* Right border *!*/
  /*border-top-left-radius: 10px; !* Top-left border-radius *!*/
  /*border-top-right-radius: 10px; !* Top-right border-radius *!*/
  padding-left: 10px;
  padding-right: 20px;
}

/* Optional: Change the border color of the outer calendar */
.fc {
  border: none; /* Outer border color */
}

/* Change the color of grid lines in the day grid */
.fc-daygrid-day-frame {
  border-color: transparent; /* Your desired color */
}

/* Change vertical and horizontal separator lines */
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #edeff1 !important; /* Your desired color */
}

/* Optional: Adjust the borders of header cells (e.g., days of the week) */
.fc-view-harness fc-view-harness-active {
  border-color: #edeff1 !important; /* Your desired color */
}

.fc-theme-standard .fc-scrollgrid {
  border-color: #edeff1;
  margin: 0;
}

/* Adjust the spacing for the title */
.fc-toolbar .fc-toolbar-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal !important;
}

/* Style for previous and next buttons */
.fc-toolbar .fc-prev-button,
.fc-toolbar .fc-next-button {
  background-color: transparent; /* Transparent background */
  color: grey; /* Default text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 6px 12px;
  cursor: pointer;
  height: 40px;
  @apply transition ease-in-out duration-300;
  /* Add Tailwind transition */
}

/* Add hover effects using Tailwind */
.fc-toolbar .fc-prev-button:hover,
.fc-toolbar .fc-next-button:hover {
  @apply bg-light-base-primary text-white;
  /* Tailwind hover styles */
}

/* Ensure consistent spacing */
.fc-toolbar .fc-prev-button + .fc-next-button {
  margin-left: 5px;
}

/* Default button styles */
.fc-toolbar .fc-button {
  background-color: transparent;
  color: grey;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-shadow: none;
}

/* Specific styles for dayGridMonth, timeGridWeek, and timeGridDay buttons */
.fc-toolbar .fc-dayGridMonth-button,
.fc-toolbar .fc-timeGridWeek-button,
.fc-toolbar .fc-timeGridDay-button {
  border-left: 2px solid #34c100;
  border-top: 2px solid #34c100;
  border-bottom: 2px solid #34c100;
  border-radius: 6px;
  width: 95px;
  outline: none;
  text-transform: uppercase;
  font-size: 14px;
}

.fc-toolbar .fc-timeGridDay-button:last-child {
  border-right: 2px solid #34c100; /* Add border-right only to the last child */
}

.fc-button-group .fc-button.fc-button-active {
  font-weight: normal !important; /* Force normal font weight */
}

@media (max-width: 768px) {
  /* Adjust for screens 768px or smaller */
  .fc-toolbar .fc-dayGridMonth-button,
  .fc-toolbar .fc-timeGridWeek-button,
  .fc-toolbar .fc-timeGridDay-button {
    width: 84px; /* Adjust width for even smaller screens */
  }
}

/* Hover styles for specific buttons */
.fc-toolbar .fc-dayGridMonth-button:hover,
.fc-toolbar .fc-timeGridWeek-button:hover,
.fc-toolbar .fc-timeGridDay-button:hover {
  background-color: #34c100;
  color: white;
  border: 2px solid #34c100;
  outline: none;
  box-shadow: none;
}

/* Active styles for specific buttons */
.fc-toolbar .fc-dayGridMonth-button.fc-button-active,
.fc-toolbar .fc-timeGridWeek-button.fc-button-active,
.fc-toolbar .fc-timeGridDay-button.fc-button-active {
  background-color: #34c100 !important;
  color: white !important;
  border-color: #34c100 !important;
  font-weight: bold;
  outline: none;
  box-shadow: none;
}

/* Forcefully remove outline and box-shadow for focus state */
.fc-toolbar .fc-dayGridMonth-button:focus,
.fc-toolbar .fc-timeGridWeek-button:focus,
.fc-toolbar .fc-timeGridDay-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #34c100; /* Optional, add a border if you need a custom focus style */
}

/* Ensure no outline for any focus state on .fc-button */
.fc-toolbar .fc-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.fc-toolbar-chunk div:first-child {
  display: flex;
  align-items: center;
}

.fc-toolbar-title {
  padding-left: 10px;
}

/* Remove the default stretching behavior */
.fc-event {
  /*width: max-content;*/
  white-space: nowrap; /* Ensure text stays on one line */
  overflow: hidden; /* Hide text overflow if any */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  font-size: 12px;
  border: none; /* Remove borders */
  background-color: transparent; /* Optional: Background color */
  border-radius: 8px; /* Optional: Rounded corners */
}

.fc-event-main {
  width: auto;
}

/* Prevent the FullCalendar grid layout from interfering with event width */
.fc-daygrid-event {
  justify-content: start !important; /* Align events to the start of the cell */
  flex-wrap: nowrap; /* Prevent wrapping */
}

.fc-more {
  color: #3498db; /* Blue color for "+x more" */
  font-weight: bold;
}
/* Fix calendar responsiveness on mobile devices*/
@media (max-width: 640px) {
  .fc .fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 12px;
  }
  .fc-toolbar .fc-toolbar-title {
    font-size: 16px;
  }

  .fc .fc-col-header-cell-cushion {
    font-size: 14px;
    font-weight: normal !important;
  }
}

.fc-col-header-cell-cushion {
  font-weight: normal !important;
}

/*calender Fine tune*/
.fc .fc-scrollgrid-section-footer > *, .fc .fc-scrollgrid-section-header > * {
  border-right: 0 !important;
}

.fc .fc-scrollgrid-section-liquid > td {
  border-right: 0 !important;
}

table.fc-scrollgrid.fc-scrollgrid-liquid{
  border-left: 0 !important;
}


/*Scroll Bar*/
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar color */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.tiptap-editor p {
  font-size: 16px;
}

.tiptap-editor h1 {
  font-size: 32px;
}

.tiptap-editor h2 {
  font-size: 24px;
}

.tiptap-editor h3 {
  font-size: 20px;
}

.tiptap-editor ul {
  list-style-type: disc;
  padding-left: 20px;
}

.tiptap-editor ol {
  list-style-type: decimal;
  padding-left: 20px;
}

/* Override default dot color */
.slick-slider .slick-dots li button:before {
  color: #939090 !important; /* Replace with your desired color */
}

/* Override active dot color */
.slick-slider .slick-dots li.slick-active button:before {
  color: #34C100 !important; /* Replace with your desired active color */
}
